import { Theme, alpha, Components } from "@mui/material/styles";
import { gray, green } from "../themePrimitives.ts";

declare module "@mui/material/styles" {
  interface Theme {
    vars?: Record<string, any>; // Add 'vars' property to Theme type
  }

  interface ThemeOptions {
    vars?: Record<string, any>; // Add 'vars' property to ThemeOptions
  }
}

/* eslint-disable import/prefer-default-export */
export const feedbackCustomizations: Components<Theme> = {
  MuiAlert: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: 10,
        backgroundColor: theme.palette.success.light, // Green for success
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.success.main}`,
        "&.MuiAlert-standardError": {
          backgroundColor: theme.palette.error.light, // Red for error
          border: `1px solid ${theme.palette.error.main}`,
          color: theme.palette.text.primary,
        },
        "&.MuiAlert-standardSuccess": {
          backgroundColor: theme.palette.success.light,
          border: `1px solid ${theme.palette.success.main}`,
        },
        "& .MuiAlert-icon": {
          color: theme.palette.success.dark,
          "&.MuiAlert-standardError &": {
            color: theme.palette.error.dark,
          },
        },
      }),
    },
  },
  MuiDialog: {
    styleOverrides: {
      root: ({ theme }) => ({
        "& .MuiDialog-paper": {
          borderRadius: "10px",
          border: "1px solid",
          borderColor: (theme.vars || theme).palette.divider,
        },
      }),
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: ({ theme }) => ({
        height: 8,
        borderRadius: 8,
        backgroundColor: gray[200],
        ...theme.applyStyles("dark", {
          backgroundColor: gray[800],
        }),
      }),
    },
  },
};
