import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import MuiCard from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import AppTheme from "./theme/AppTheme.tsx";
import CircularProgress from "@mui/material/CircularProgress";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "450px",
  },
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  height: "calc((1 - var(--template-frame-height, 0)) * 100dvh)",
  minHeight: "100%",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    zIndex: -1,
    inset: 0,
    backgroundImage:
      "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
    backgroundRepeat: "no-repeat",
    ...theme.applyStyles("dark", {
      backgroundImage:
        "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
    }),
  },
}));

const ResetPassword = () => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchResetDetails = async () => {
      const query = new URLSearchParams(location.search);
      const token = query.get("token");
      const user_id = query.get("user_id");

      setUser(user_id);

      try {
        const response = await axios.get(
          "https://magicpages.propstory.com/user/reset/",
          {
            params: { token, user_id },
          }
        );

        if (response.data.message === "password reset link ok") {
          setSnackbar({
            open: true,
            message: "Password reset link verified.",
            severity: "success",
          });
        } else {
          setSnackbar({
            open: true,
            message: "Invalid or expired link.",
            severity: "error",
          });
        }
      } catch (err) {
        setSnackbar({
          open: true,
          message: "Error verifying reset link.",
          severity: "error",
        });
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchResetDetails();
  }, [location.search]);

  const handleUpdatePassword = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const response = await axios.post(
          "http://localhost:3003/user/updatePassword",
          {
            password,
            user_id: user,
          }
        );

        if (response.data.message === "password updated") {
          setSnackbar({
            open: true,
            message: "Password updated successfully. Redirecting...",
            severity: "success",
          });
          setTimeout(() => navigate("/login-page"), 3000);
        } else {
          setSnackbar({
            open: true,
            message: "Failed to update password. Try again.",
            severity: "error",
          });
        }
      } catch (err) {
        setSnackbar({
          open: true,
          message: "Something went wrong. Please try again.",
          severity: "error",
        });
        console.error(err);
      }
    },
    [password, user, navigate]
  );

  const handleCloseSnackbar = (_, reason) => {
    if (reason === "clickaway") return;
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Full page height for centering
          backgroundColor: "background.default",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <React.Fragment>
      <AppTheme>
        <CssBaseline />
        <SignInContainer>
          <Card variant="outlined">
            <Typography component="h1" variant="h4">
              Reset Password
            </Typography>
            <Box
              component="form"
              noValidate
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
              onSubmit={handleUpdatePassword}
            >
              <FormControl>
                <FormLabel htmlFor="password">New Password</FormLabel>
                <TextField
                  id="password"
                  type="password"
                  placeholder="Enter your new password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
              <Button type="submit" fullWidth variant="contained">
                Update Password
              </Button>
            </Box>
            <Divider />
            <Typography sx={{ textAlign: "center" }}>
              Back to{" "}
              <a href="https://magicpages.propstory.com/login-page">Login</a>
            </Typography>
          </Card>
          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={snackbar.severity}
              sx={{ width: "100%" }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </SignInContainer>
      </AppTheme>
    </React.Fragment>
  );
};

export default ResetPassword;
