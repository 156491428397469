import { AuthAction } from "../actions";
import API from "../../config/api";

export default class AuthMiddleware {
  static SignUp(fullName, email, password, company) {
    return (dispatch) => {
      dispatch(AuthAction.Signup());

      return API.post("user", {
        name: fullName,
        email: email,
        password: password,
        company: company,
      })
        .then((success) => {
          dispatch(
            AuthAction.SignupSuccess({
              id: success.data.user._id,
              name: success.data.user.name,
              email: success.data.user.email,
              company: success.data.user.company,
              createdDate: success.data.user.created_date,
            })
          );
          return success; // Resolve with success data if needed
        })
        .catch((err) => {
          const errorMessage =
            err.response?.data?.message || "Something went wrong!";
          dispatch(AuthAction.SignupFailure(errorMessage));
          return Promise.reject(errorMessage);
        });
    };
  }

  static AddCompany(companyName, reraNo, gstin, email, user_id) {
    return (dispatch) => {
      dispatch(AuthAction.AddCompany());

      // Return the first API call for chaining
      return API.post("company", {
        name: companyName,
        reraNo: reraNo,
        gstin: gstin,
        email: email,
      })
        .then((result) => {
          dispatch(
            AuthAction.AddCompanySuccess({
              name: result.data.company.name,
              reraNo: result.data.company.reraNo,
              gstin: result.data.company.gstin,
              email: result.data.company.email,
            })
          );

          return API.post("user/updateCompany", {
            user_id: user_id,
            company_id: result.data.company._id,
            enabled: true,
          }).then((res) => {
            console.log("Company updated successfully", res);
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          // Use navigate in your component after this function returns
        });
    };
  }

  static SignIn(email, password) {
    return (dispatch) => {
      // Dispatch an action to indicate the start of the login process
      dispatch(AuthAction.Signin());

      // Return the API call to allow chaining
      return API.post("user/login", {
        email: email,
        password: password,
      })
        .then((result) => {
          const user = result.data.user;
          console.log("USER:", user);

          // Dispatch success action with user details
          dispatch(
            AuthAction.SigninSuccess({
              id: user._id,
              name: user.name,
              email: user.email,
              token: result.data.token, // Assuming the token is here
              createdDate: user.created_date,
            })
          );

          // Store user ID in localStorage for session persistence
          localStorage.setItem("userId", user._id);

          // Resolve the Promise (important for chaining in the caller)
          return result;
        })
        .catch((err) => {
          // Dispatch failure action
          dispatch(AuthAction.SigninFailure(err));

          // Throw an error to allow the caller to handle it
          const errorMessage =
            err.response?.data?.message || "Invalid username or password.";
          throw new Error(errorMessage);
        });
    };
  }

  static ForgotPass(email) {
    return (dispatch) => {
      dispatch(AuthAction.ForgotPass());

      return API.post("/user/forgotPassword", {
        email: email,
      })
        .then((result) => {
          dispatch(
            AuthAction.SigninSuccess({
              id: result.data.user._id,
              name: result.data.user.name,
              email: result.data.user.email,
              createdDate: result.data.user.created_date,
            })
          );
        })
        .catch((err) => {
          dispatch(AuthAction.SigninFailure(err));
        });
    };
  }
}
