import React from "react";
import { Route, Routes } from "react-router-dom";
import Index from "./views/Index.js";
import LoginPage from "./views/Pages/LoginPage.js";
import ForgotPasswordPage from "./views/Pages/ForgotPasswordPage.js";
import ResetPassword from "./views/Pages/ResetPassword.js";
import AdminLayout from "./views/Admin.jsx";
import SignUpPage from "./views/Pages/SignUpPage.js";
import PageDetail from "./views/Pages/PageDetail.jsx";
import EditAsHTML from "./views/Pages/EditAsHTML.jsx";
import Logout from "./views/Pages/Logout.js";
import "./assets/css/bootstrap.min.css";
import "./assets/scss/now-ui-kit.scss";
import "./assets/css/animate.min.css";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import Dashboard from "./views/Pages/Dashboard.jsx";
import TemplatePages from "./views/Pages/TemplatePages.jsx";
import Pages from "./views/Pages/Pages.jsx";
import EditImage from "./views/Pages/EditImage.js";
import Profile from "./views/Pages/Profile.js";
import Settings from "./views/Pages/Settings.js";
import BackupLeads from "./views/Pages/BackupLeads.js";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute.js";

const App = () => (
  <Routes>
    {/* <Route path="/" element={<Index />} /> */}
    <Route path="/login-page" element={<LoginPage />} />
    <Route path="/forgot-password" element={<ForgotPasswordPage />} />
    <Route path="/reset-password" element={<ResetPassword />} />
    <Route path="/signUp-page" element={<SignUpPage />} />

    {/* Protected Admin Routes */}
    <Route
      path="/admin/*"
      element={
        <ProtectedRoute>
          <AdminLayout />
        </ProtectedRoute>
      }
    >
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="template_pages" element={<TemplatePages />} />
      <Route path="pages" element={<Pages />} />
      <Route path="editImages" element={<EditImage />} />
      <Route path="profile" element={<Profile />} />
      <Route path="settings" element={<Settings />} />
      <Route path="backupLeads" element={<BackupLeads />} />
      <Route path="logout" element={<Logout />} />
      <Route path="pageDetails" element={<PageDetail />} />
      <Route path="editAsHTML" element={<EditAsHTML />} />
    </Route>
    <Route path="/admin/pageDetails" element={<PageDetail />} />
    <Route path="/admin/editAsHTML" element={<EditAsHTML />} />
  </Routes>
);

export default App;
